import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/dashboard.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }, {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, {
    path: '/newsView',
    name: 'newsView',
    component: () => import(/* webpackChunkName: "about" */ '../views/news/newsView.vue')
  }, {
    path: '/newDetial',
    name: 'newDetial',
    component: () => import(/* webpackChunkName: "about" */ '../views/news/newDetial.vue')
  },
  { path: '*', redirect: '/' }
]
console.log(process.env.VUE_APP_API_ROUTER, 9999)
const router = new VueRouter({
  routes,
  mode: process.env.VUE_APP_API_ROUTER,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
