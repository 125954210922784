<template>
  <div class="head">
    <div class="head-left  flex-row"
         @click="goHeader()"
         style="cursor:pointer">
      <div class="block"><img src="@/assets/image/logo.png"
             alt=""></div>
      <div class="info">南果数字化平台</div>
    </div>
    <div class="head-right justify-end">
      <el-menu :default-active="$route.path"
               router
               active-text-color="#51a7ff"
               mode="horizontal">
        <el-menu-item v-for="(item,i) in navList"
                      :key="i"
                      :index="item.name">
          {{ item.navItem }}
        </el-menu-item>
      </el-menu>
      <div class="head-but justify-around">
        <a :href='login'
           target="_blank">登录</a>
        <span>|</span>
        <a :href="register"
           target="_blank"> 注册</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'fuilt-header',
  data () {
    return {
      login: '',
      register: '',
      navList: [
        { name: '/', navItem: '首页' },
        { name: '/about', navItem: '关于我们' },
        { name: '/home', navItem: '我们的宗旨' },
        { name: '/newsView', navItem: '最新资讯' }
      ]
    }
  },
  created () {
    this.login = process.env.VUE_APP_API_LOGIN_URL
    this.register = process.env.VUE_APP_API_LOGIN_URL + '/user/register/select'
  },
  methods: {
    goHeader (n) {
      console.log(1234)
      this.$router.push('/')
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.head {
  height: 70px;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  &-left {
    width: 50%;
    margin-left: 100px;
    .info {
      width: 250px;
      height: 32px;
      color: rgba(45, 109, 246, 1);
      font-size: 24px;
      margin: 20px 0 0 8px;
    }
    .block {
      width: 105px;
      height: 40px;
      margin-top: 16px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .head-right {
    margin-left: 25%;
    .head-but {
      position: relative;
      width: 113px;
      height: 32px;
      margin: 19px;
      padding-left: 20px;
      border: 1px solid #333333;
      border-radius: 20px;
      a {
        display: block;
        width: 50px;
        height: 33px;
        line-height: 33px;
        text-align: center;
        padding-left: 0;
        font-size: 14px;
        color: #2c3e50;
      }
      span {
        position: absolute;
        color: #2c3e50;
        margin: 4px 0;
        margin-right: 22px;
      }
    }
    a {
      display: inline-block;
      padding: 0 24px;
      font-size: 16px;
      color: #2c3e50;
      line-height: 65px;
      text-align: center;
      &.router-link-active {
        span {
          width: 68px;
          padding: 25px 0;
          border-bottom: 3px solid rgb(45, 109, 246);
        }
      }
    }
  }
}
.el-menu--horizontal > .el-menu-item {
  height: 70px;
  line-height: 70px;
  font-size: 16px;
  color: #000;
  margin-right: 8px;
}
::v-deep .el-menu--horizontal{
  width: 450px;
}
</style>
