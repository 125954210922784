
<template>
  <div id="app">
    <nav class="nav">
      <fuiltHeader></fuiltHeader>
    </nav>
    <router-view class="main" />
    <footer>
      <fuiltFooter></fuiltFooter>
    </footer>
  </div>
</template>
<script>
import fuiltHeader from '@/components/fuiltHeader.vue'
import fuiltFooter from '@/components/fuiltFooter.vue'
export default {
  components: {
    fuiltHeader,
    fuiltFooter
  }
}
</script>

<style lang="scss">
#app {
  color: #fff;
}
nav {
  width: 100%;
  position: fixed;
  background-color: #fff;
  z-index: 9999999999999;
  border-bottom: 1px solid rgb(207, 207, 207);
}
.main {
  padding-top: 70px;
  min-height: 100vh;
}
footer {
  background-color: #000;
  background-size: 100% 100%;
}
</style>
