<template>
  <div class="footer">
    <div class="footer-up flex-col">
      <span class="paragraph1">促进果农、果商、果库主与金融机构“质押贷款”业务合作<br />解决金融机构信贷支持南果产业“质押存货”监管难瓶颈</span>
    </div>
    <div class="footer-down justify-between ">
      <div class="footer-down-left flex-col">
        <span>联系我们</span>
         <span>备案号:<a href="https://beian.miit.gov.cn" target="_blank">晋ICP备2022006086号-1</a> </span>
        <span>详细地址：*********··</span>
      </div>
      <div class="footer-down-right flex-row">
        <!-- <div class="flex-col left">
          <div class="foot-pic"></div>
          <div>微信公众号</div>
        </div> -->
        <div class="flex-col">
          <img class="foot-pic" src="@/assets/image/weapp.jpg" />
          <div>微信小程序</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.footer {
  z-index: 99999;
  width: 100%;
  .footer-up {
    height: 209px;
     background-color: #262a30;
    margin-top: 44px;
    .paragraph1 {
      width: 100%;
      height: 104px;
      color: rgba(255, 255, 255, 1);
      font-size: 30px;
      line-height: 52px;
      text-align: center;
      margin: 47px 0 0 -25px;
    }
  }
  .footer-down {
    height: 214px;
   background-color:#02060c;
    &-left {
      color: #fff;
      width: 400px;
      height: 160px;
      line-height: 40px;
      margin-top: 40px;
      margin-left: 100px;
      span{
        a{
          margin-left: 10px;
          color: #fff;
        }
      }
    }
    &-right {
      color: #fff;
      text-align: center;
      margin: 40px 100px;
      .foot-pic {
        width: 112px;
        height: 112px;
        background-color: #fff;
      }
      .left {
        margin-right: 50px;
      }
    }
  }
}
</style>
